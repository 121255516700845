import {NavLink, useNavigate} from "react-router-dom";
import '../../index.css';
import '../../style/about.css';
import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import profile from "../../asset/hiroki.png";

function About_jp() {
    const navigate = useNavigate();

    useEffect(() => {
        const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD');

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }


        const options = {
            root: null, // 今回はビューポートをルート要素とする
            rootMargin: "0px 0px -20%", // ビューポートの中心を判定基準にする
            threshold: 0 // 閾値
        };
        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
    });

    function jumptop() {
        const element = document.getElementById("top");
        element.scrollIntoView();
    }


    return (<div className={"subpage bio-page style_jp"}>
            <Helmet>
                <title>プロフィール | Hiroki KURODA Site Officiel </title>
            </Helmet>
            <h2 className="fade-inD">プロフィール</h2>
            <p className="fade-in"><span>1980</span>年島根県生まれ、大阪のお笑い文化の中で育ち、夏休みには田舎で自然を学び、スポーツをする事が大好きで自分自身を表現していた。10代の終わりからはDj
                KURO としてダンスミュージックと出会いヨーロッパ文化に興味を抱いた。同時期に料理人として拠点を京都に移し日本料理の伝統精神《一期一会、花鳥風月、和敬清寂、侘び寂び》などの表現の美しさに日本を感じた。
            </p>
            <p className="fade-in">30代には様々な体験や出会いを通じて渡仏を決意。料理人として世界の人々と和食の可能性を共鳴し合う事で表現者として大きな成長を遂げた。
            </p>
            <p className="fade-in">現在は、無邪気に自己表現していた幼少期の記憶に帰りつき、挑戦できる事に感謝しながらアーティストとして、自分を表現している。最近では、自然の美しさや人間の素晴らしさ、恐ろしさなどをテーマに絵を描いている。
            </p>
            <p className="fade-in">作品を生み出すことによって、また一つ自分になった。私はいつも新しくいたいようだ。</p>
            <p className="fade-in">この宇宙の中で自分の魂と同じ香りのする瞬間、私はまた自分に帰れる気がする。</p>
            <p className="fade-in"><img src={profile} alt=""/></p>
            <p className="fade-in">黒田裕樹<br/>Hiroki KURODA</p>
            <div className="btn_palette fade-in" onClick={jumptop}>
                <NavLink to="/jp/contact" className="">
                    お問い合わせへ
                </NavLink>
            </div>

        </div>
    );
}

export default About_jp;

