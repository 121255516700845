import '../../style/contact.css';
import {useEffect, useState} from "react";
import Contactform from "./contactform";
import {Helmet} from "react-helmet-async";


function Contact_en(props) {

    useEffect(() => {
        const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD');

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }

        const options = {
            root: null, // 今回はビューポートをルート要素とする
            rootMargin: "0px 0px -10%", // ビューポートの中心を判定基準にする
            threshold: 0 // 閾値
        };
        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
    });


    return (<div className={"subpage"}>
        <Helmet>
            <title>Contact | Hiroki KURODA Site Officiel </title>
        </Helmet>
        <h2 className="Corsiva fade-inD">Contact</h2>
        <Contactform/>
    </div>)
}

export default Contact_en;


