function NoMatch() {
    return (
        <din className="page404">
            <h2>404 : <br/> Cette page n'existe pas.</h2>
            <h2>404 : <br/> This page does not exist.</h2>
            <h2>404 : <br/> このページは存在しません。</h2>
        </din>
   )


}

export default NoMatch;