import {Link, Outlet, Route, Routes, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useForm} from "react-hook-form";
import emailjs from '@emailjs/browser'
import '../../style/contact.css';
import '../../style/oeuvres.css';

function Oeuvres_jp(props) {
    emailjs.init("IVL51MkkjSpEkqzH6");

    let [work, setWork] = useState({});

    useEffect(() => {

        const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD,.fade-inTurn');

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }

        const options = {
            root: null,
            rootMargin: "0px 0px -10%",
            threshold: 0
        };

        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
    }, []);

    const is_titled = ((title) => {
        if (title) {
            return title
        } else {
            return "sans titre"
        }
    })

    const openContact = ((item) => {
        setWork(item)
        window.scrollTo(0, 0);
        document.getElementById("head").scrollIntoView();
    })

    const work_status = ((i) => {
        const array = [...props.works]
        if (array[i].url && array[i].indisponible === 'TRUE') {
            return (<div key={i} className="galerie_box">
                <div className="galerie_shadow nonsidp"><img src={array[i].url} alt=""/></div>
                <p className="galerie_title">{is_titled(array[i].name_jp)}</p>
                <p className="galerie_size"><span>{array[i].tatecm}</span>cm
                    x <span>{array[i].yokocm}</span>cm
                </p>
                <p className="galerie_type"><span>Acrylique sur toile</span></p>
                <p className="galerie_size"><span>{array[i].year}</span></p>
                <div className="nonsales">
                    <p>非売品</p>
                </div>
            </div>)

        }
        if (array[i].url && array[i].indisponible === 'FALSE') {
            if (array[i].vendu === 'TRUE') {
                return (
                    <div key={i} className="galerie_box">
                        <div className="galerie_shadow sold"><img src={array[i].url} alt=""/></div>
                        <p className="galerie_title">{is_titled(array[i].name_fr)}</p>
                        <p className="galerie_size"><span>{array[i].tatecm}</span>cm
                            x <span>{array[i].yokocm}</span>cm </p>
                        <p className="galerie_type"><span>{array[i].type}</span></p>
                        <p className="galerie_size"><span>{array[i].year}</span></p>
                        <div className="undisp">
                            <p>Vendu</p>
                        </div>
                    </div>)

            } else {
                return (
                    <div key={i} className="galerie_box">
                        <div className="galerie_shadow"><img src={array[i].url} alt="sakuhin"/></div>
                        <p className="galerie_title">{is_titled(array[i].name_jp)}</p>
                        <p className="galerie_size"><span>{array[i].tatecm}</span>cm
                            x <span>{array[i].yokocm}</span>cm
                        </p>
                        <p className="galerie_type"><span>Acrylique sur toile</span></p>
                        <p className="galerie_size"><span>{array[i].year}</span></p>
                        <p className="galerie_prix"><span>{array[i].price}</span> €</p>
                        <div className="galerie_btn">
                            <label htmlFor="tocontact" onClick={(() => {
                                openContact(array[i])
                            })}>購入お問い合わせ</label>
                        </div>
                    </div>)
            }
        }
    })

    const renderList = (() => {
            const result = [];
            const array = [...props.works]
            for (let i = 0; i < array.length; i++) {
                if (array[i].tatecm / array[i].yokocm < 0.84) {
                    result.push(
                        <div className="item_wide">{work_status(i)}</div>
                    )
                } else {
                    result.push(
                        <div className="item_half">{work_status(i)}</div>
                    )
                }
            }

            return (<div className="sec_galerie">
                    <h2 className="Corsiva fade-inD">アートギャラリー</h2>
                    <div className="sec_galerie_container">{result}</div>
                </div>
            );
        }
    )

    emailjs.init("IVL51MkkjSpEkqzH6");

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailcheck, setEmailcheck] = useState('');
    const [message, setMessage] = useState('');
    let [submit, setSubmit] = useState(false);

    const {
        register, handleSubmit, formState: {errors},
    } = useForm({
        mode: 'onBlur', reValidateMode: 'onBlur', criteriaMode: 'all',
    });

    const form = useRef();
    useEffect(() => {
        if (firstname && lastname && !emailcheck && message) {
            setSubmit(true)
        } else {
            setSubmit(false)
        }
    });

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        console.log(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event) => {
        const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
        if (pattern.test(event.target.value)) {
            setEmailcheck("")
        } else {
            setEmailcheck("invalide")
        }
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const onSubmit = () => {
        emailjs.sendForm('service_1n5f7ji', 'template_9e8uzq1', form.current)
            .then(function (response) {
                window.alert("Votre message est bien envoyé! Merci.");
            }, function (error) {
                window.alert("Il y a eu une erreur de transmission.");
                console.log('FAILED...', error);
            });
    };

    return (<div className={"subpage style_jp"}>

        <Helmet>
            <title>アートギャラリー | Hiroki KURODA Site Officiel</title>
        </Helmet>

        <div id="head"></div>

        <input type="checkbox" id="tocontact"/>

        {renderList()}

        <div className="contactWithId top_form " id="contactWithId">
            <label htmlFor="tocontact">ギャラリーへ</label>
            <h2 className={"Corsiva"}>" {work.name_jp} " の <br/>購入お問い合わせ</h2>
            <div className="galerie_shadow"><img src={work.url} alt=""/></div>
            <form onSubmit={handleSubmit(onSubmit)} method="post" ref={form}>

                <input type="text" value={work.name_jp} name="title" className="hidden"/>
                <input type="text" value={work.url} className="hidden"/>
                <input type="text" value={work.id} name="artid" className="hidden"/>

                <div className={"form_namebox"}>
                    <div>
                        <input type="text" placeholder="名"
                               value={firstname} {...register('firstname', {required: true})}
                               onChange={handleFirstNameChange}/>
                        {errors.firstname && <div className="form_alert">* 必須項目です</div>}</div>
                    <div>
                        <input type="text" placeholder="姓"
                               value={lastname}{...register('lastname', {required: true})}
                               onChange={handleLastNameChange}/>
                        {errors.lastname && <div className="form_alert">* 必須項目です</div>}</div>
                </div>
                <input type="email" placeholder="your_email@address.com" className="width100"
                       value={email}   {...register('email', {required: true})}
                       onChange={handleEmailChange}/>
                {errors.email && <div className="form_alert">* 必須項目です</div>}
                {!errors.email && emailcheck && <div className="form_alert">* メールアドレスが有効ではありません</div>}
                <textarea placeholder="メッセージ" className={"width100"}
                          value={message} {...register('message', {required: true})}
                          onChange={handleMessageChange}/>
                {errors.message && <div className="form_alert">* 必須項目です</div>}

                <div className={"form_namebox"}>
                    <div className={"width50"}></div>
                    <input type="submit" className={"Playfair"} disabled={!submit} value="送信"/>
                </div>
            </form>
        </div>
    </div>)
}

export default Oeuvres_jp;