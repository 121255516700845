import {NavLink, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import icon_insta from '../asset/iconblack.png';
import '../style/navi.css';

function Navi() {
    const location = useLocation();
    console.log(location.pathname.substring(1, 3));

    function nav_off() {
        const element = document.getElementById("top");
        element.scrollIntoView();
        document.getElementById("navi_checkbox").checked = false;
        console.log("nav_off")
    }

    function navi_contentByLangue() {
        const extractedChars = location.pathname.substring(1, 3);
        console.log(extractedChars);
        switch (extractedChars) {
            case 'en':
                return (<ul onClick={nav_off}>
                    <li><NavLink
                        to="/en">
                        Home
                    </NavLink></li>

                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="en/oeuvres">
                        Art Gallery
                    </NavLink></li>
                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="en/tarot">
                        Reading <span>the Marseille Tarot</span>
                    </NavLink></li>

                    <li><NavLink
                        to="en/biographie">
                        Biography
                    </NavLink></li>
                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="en/contact">
                        Contact
                    </NavLink></li>
                </ul>);
            case 'jp':
                return (<ul onClick={nav_off} className="style_jp">
                    <li><NavLink
                        to="/jp">
                        <span>ホーム</span> / Home
                    </NavLink></li>

                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="jp/oeuvres">
                        <span>ギャラリー</span> / Art Gallery
                    </NavLink></li>
                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="jp/tarot">
                        <span>タロットリーディング</span> / Tarot
                    </NavLink></li>

                    <li><NavLink
                        to="jp/biographie">
                        <span>プロフィール</span> / Biography
                    </NavLink></li>
                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="jp/contact">
                        <span>お問い合わせ</span> / Contact
                    </NavLink></li>
                </ul>);
            default:
                return (<ul onClick={nav_off}>

                    <li><NavLink
                        to="/">
                        Accueil
                    </NavLink></li>

                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="/oeuvres">
                        Œuvres d'Art
                    </NavLink></li>
                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="/tarot">
                        Tirage de Tarot <span>de Marseille</span>
                    </NavLink></li>

                    <li><NavLink
                        to="/biographie">
                        Biographie
                    </NavLink></li>
                    <li><NavLink
                        className={({isActive}) => (isActive ? 'active' : undefined)}
                        to="/contact">
                        Contact
                    </NavLink></li>
                </ul>);
        }
    }


    return (<div className="navi">
        <input id="navi_checkbox" type="checkbox"/>
        <div className="navi_trigger">
            <label htmlFor="navi_checkbox" className="navi_trigger_label"><p>Hiroki</p></label>
            <div className="navi_trigger_footer">
                <NavLink to="/" className="langue Playfair">FR</NavLink>
                <NavLink to="/en" className="langue Playfair">EN</NavLink>
                <NavLink to="/jp" className="langue Playfair">JP</NavLink>
                <a href="https://www.instagram.com/hk11paris/" target='_blank'>
                    <img src={icon_insta} alt="icon" className="link_insta"/>
                </a>
            </div>
        </div>
        <div className="navi_content">
            {navi_contentByLangue()}
        </div>
    </div>);
}

export default Navi;