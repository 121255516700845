import {NavLink, useNavigate} from "react-router-dom";
import '../../index.css';
import '../../style/about.css';
import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import profile from "../../asset/hiroki.png";

function About_en() {
    const navigate = useNavigate();

    useEffect(() => {
        const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD');

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }



        const options = {
            root: null, // 今回はビューポートをルート要素とする
            rootMargin: "0px 0px -20%", // ビューポートの中心を判定基準にする
            threshold: 0 // 閾値
        };
        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
    });

    function jumptop() {
        const element = document.getElementById("top");
        element.scrollIntoView();
    }


    return (<div className={"subpage bio-page"}>
        <Helmet>
            <title>Biography | Hiroki KURODA Site Officiel </title>
        </Helmet>
        <h2 className="Corsiva fade-inD"> Biography</h2>
        <h3 className="fade-in">About me</h3>
        <p className="fade-in"><span>B</span>orn in Shimane, Japan in 1980, I grew up in the comedy culture of Osaka, spent summer vacations in the countryside learning about nature, loved playing sports and expressing myself, and from the end of my teens I discovered dance music as Dj KURO and became interested in European culture. Around the same time, he moved to Kyoto to work as a chef, and felt Japan through the beauty of the traditional spirit of Japanese cuisine, such as "Ichi-go ichi-e(one time, one meeting)"," "Kacho-fu-getsu(Flower, Bird, Wind, Moon.)," "Wabi-Sabi(Beauty in natural simplicity)," and "Harmonious, Pure, and Satisfying".
        </p>
        <p className="fade-in"><span>I</span>n his 30s, through various experiences and encounters, he decided to go to France. As a chef, he has achieved great growth as an expressive person by resonating the possibilities of Japanese cuisine with people around the world.
        </p>
        <p className="fade-in"><span>C</span>urrently, I am returning to my childhood memories of innocent self-expression and expressing myself as an artist, grateful for the challenges I can take on. Recently, she has been expressing herself by creating works with messages about the importance of nature, the wonder and horror of human beings, spiritual works, and works that are as powerful as they are beautiful, all the while devoting herself to her mission.
        </p>
        <p className="fade-in"> <span>B</span>y creating works of art, I have become myself again. I seem to always want to be new.
        </p>
        <p className="fade-in"><span>T</span>he moment I feel the same fragrance as my soul in this universe, I feel like I can be myself again.
        </p>
<p className="fade-in"><img src={profile} alt=""/></p>
        <p className="fade-in">Hiroki KURODA</p>
        <div className="btn_palette fade-in" onClick={jumptop}>
            <NavLink to="/en/contact" className="">
                Get in touch
            </NavLink>
        </div>

    </div>);
}

export default About_en;

// Hiroki KURODA
//
// 1980年島根県生まれ　少年期はラグビー、エレクトーン、思春期はサッカーに明け暮れ、十代の終わりからDj KURO として大阪のクラブシーンを盛り上げる。同時期頃に始めた板前としての修行の拠点を京都に移し京懐石での花鳥風月、和敬清寂　侘び寂び　などの世界観の美しさに多いに触れる事になる。
// 30代になる頃には今までの大きな学びと色々な方々達の出会いや導きのお陰で生活の拠点をフランスに移し料理人として日本人として和食を通じて世界の人達と共感、共鳴し合いながら表現者として大きく成長していく。
// 時と共に料理人だけとしてはとどまる事もなく無邪気に自己表現していた幼少期の記憶に帰り、挑戦できる事に感謝しながらアーティストとして、画家として自分を表現する事によって人々に癒しであったり楽しんで頂けたらと自分の使命に向き合っている。
// 作品を生み出すことによって、また一つ自分になった。私はいつも新しくいたいようだ。
// この宇宙の中で自分の魂と同じ香りのする瞬間、私はまた自分に帰れる気がする。
// 自分とは太陽であり、神であり、惑星であり、愛である。
// 自分とは自分である。
//
// 龍は常に自分の中に存在していて回りにもいる共に生きている神。











    //  <p className="fade-in"> <span>J</span>e suis né en 1980 dans la préfecture de Shimane au Japon.
//
//             J'étais obsédé par le rugby et l'électone quand j'étais petit et par le football quand j'étais adolescent.
//
//             À la fin de mon adolescence, j'étais populaire en tant que DJ à Osaka.</p>
//         <p  className="fade-in"> <span>À</span> la même époque, j'ai déménagé à Kyoto pour suivre une formation d'itamae (chef cuisinier) et je suis entré
//             en contact avec la beauté du monde du kyokaiseki (cuisine kaiseki de style kyoto), notamment la beauté des
//             fleurs, des oiseaux, du vent et de la lune, le wabi et le sabi (harmonie, respect, pureté et tranquillité).
//         </p>
//         <p  className="fade-in"> <span>À</span> la trentaine, j'ai beaucoup appris et, grâce aux conseils et aux rencontres de nombreux individus, j'ai
//             déplacé ma base de vie en France.
//
//             En tant que chef et Japonais, j'ai grandi en tant qu'"homme qui exprime quelque chose" tout en sympathisant
//             et en résonnant avec des personnes de différents pays par le biais de la cuisine.
//
//             À un certain moment, je retrouve les souvenirs de mon enfance, lorsque je m'exprimais innocemment.
//             Je me suis donné pour mission de soigner et de divertir les gens avec mon travail, et je suis reconnaissant
//             de relever ce défi.
//         </p>
//         <h3>Un artist.</h3>
//         <p  className="fade-in"> <span>L</span>orsque je crée mon œuvre, je redeviens un nouveau moi. Il semble que je veuille toujours être nouveau.
//
//             Dès que je sens le même parfum que mon âme dans cet univers, je sens que je peux revenir à moi.
//         </p>
//         <p  className="fade-in"><span>J</span>e suis le soleil, les planètes, Dieu et l'amour.
//
//             Je suis moi-même.
//
//             Le dragon est le dieu qui vit avec moi, qui est toujours présent en moi et autour de moi.
//         </p>