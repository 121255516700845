import '../../style/contact.css';
import {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import emailjs from '@emailjs/browser';

function ContactForm_jp(props) {

    emailjs.init("IVL51MkkjSpEkqzH6");

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailcheck, setEmailcheck] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [artid, setArtid] = useState({});
    let [submit, setSubmit] = useState(false);

    const {
        register, handleSubmit, formState: {errors},
    } = useForm({
        mode: 'onBlur', reValidateMode: 'onBlur', criteriaMode: 'all',
    });
    const form = useRef();
    useEffect(() => {
        if (firstname && lastname && !emailcheck && message) {
            setSubmit(true)
        } else {
            setSubmit(false)
        }
    });
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        console.log(event.target.value);
    };
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };


    const handleEmailChange = (event) => {
        const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
        if (pattern.test(event.target.value)) {
            setEmailcheck("")
        } else {
            setEmailcheck("invalide")
        }
        setEmail(event.target.value);
    };
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const onSubmit = () => {
        emailjs.sendForm('service_1n5f7ji', 'template_4dd12eg', form.current)
            .then(function (response) {
                window.alert("メッセージが送信されました！ありがとうございました。");
            }, function (error) {
                window.alert("送信エラーが発生しました。");
                console.log('FAILED...', error);
            });
    };

    return (<div className="top_form">
        <form onSubmit={handleSubmit(onSubmit)} method="post" ref={form}>
          <div className={"form_namebox"}>
                <div>
                    <input type="text" placeholder="名"
                           value={firstname} {...register('firstname', {required: true})}
                           onChange={handleFirstNameChange}/>
                    {errors.firstname && <div className="form_alert">* 必須項目です</div>}</div>
                <div>
                    <input type="text" placeholder="姓"
                           value={lastname}{...register('lastname', {required: true})}
                           onChange={handleLastNameChange}/>
                    {errors.lastname && <div className="form_alert">* 必須項目です</div>}</div>
            </div>
            <input type="email" placeholder="your_email@address.com" className="width100"
                   value={email}   {...register('email', {required: true})}
                   onChange={handleEmailChange}/>
            {errors.email && <div className="form_alert">* 必須項目です</div>}
            {!errors.email && emailcheck && <div className="form_alert">* メールアドレスが有効ではありません</div>}
            <textarea placeholder="メッセージ" className={"width100"}
                      value={message} {...register('message', {required: true})}
                      onChange={handleMessageChange}/>
            {errors.message && <div className="form_alert">* 必須項目です</div>}

            <div className={"form_namebox"}>
                <div className={"width50"}></div>
                <input type="submit" className={"style_jp"} disabled={!submit} value="送信"/>
            </div>
        </form>
    </div>)
}

export default ContactForm_jp;