import '../index.css';
import {useEffect, useRef, useState} from "react";
import {getStorage, ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";
import '../style/maste.css';
import {initializeApp} from "firebase/app";

function Master() {

    const firebaseConfig = {
        apiKey: "AIzaSyDyqqHmdVM6--sWXZyjyH2YEHPn3ieELKM",
        authDomain: "kuroda-site.firebaseapp.com",
        projectId: "kuroda-site",
        storageBucket: "kuroda-site.appspot.com",
        messagingSenderId: "302447575630",
        appId: "1:302447575630:web:b6b3185e92d31d67fdb91f",
        measurementId: "G-E0LQ3BTB2B"
    };

    const app = initializeApp(firebaseConfig);

    const [files, setFiles] = useState("");
    const [percent, setPercent] = useState(0);
    const [url, setUrl] = useState("*まだアップロードされていません");


    useEffect(() => {

    });

    function copyToclipbord() {
        navigator.clipboard.writeText(url);
        alert("URLをコピーしました" + url);
    }


    function handleChange(event) {
        let fileBox = document.getElementById('fileBox');
        let files = fileBox.files;
        setFiles(files);
    }

    const handleUpload = () => {
        if (!files) {
            alert("Please upload an image first!");
        }
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            const storage = getStorage();
            const storageRef = ref(storage, '/portfolios/' + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on("state_changed", (snapshot) => {
                const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setPercent(percent);
            }, (err) => console.log(err), () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(file.name + " , upload succés!");
                    console.log(url);
                    setUrl(url)
                });
            });
        }
    };


    return (<div className={"master"}>
        <h2>Upload Image</h2>
        <div>
            <p>ファイルを選択</p>
            <input type="file" id="fileBox" multiple accept="image/*" onChange={handleChange}/>
            <button onClick={handleUpload}>アップロード</button>
            <p>{percent} % 完了</p>
            <p>作品URL</p>
            <p>{url}</p>
            <button onClick={copyToclipbord}>URLをコピー</button>
        </div>
        <div className={"green"}>
            <button><a target="_blank" href="https://docs.google.com/spreadsheets/d/19Fk8tzP3077W0bDqO0r-3DiJ582Glb5k7O2DDTJGqIc/edit#gid=569286709">スプレッドシート <br/> を編集する</a></button>
        </div>
    </div>);
}

export default Master;