import './App.css';
import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation, useParams} from "react-router-dom";
import Home from "./routes/home";
import About from "./routes/about";
import Contact from "./routes/contact";
import Oeuvres from "./routes/oeuvres";
import NoMatch from "./routes/nomatch";
import Tarot from "./routes/tarot";
import Master from "./routes/Master";
import Oeuvres_en from "./routes/en/oeuvres";
import Contact_en from "./routes/en/contact";
import Home_en from "./routes/en/home";
import Tarot_en from "./routes/en/tarot";
import About_en from "./routes/en/about";
import Home_jp from "./routes/jp/home";
import Tarot_jp from "./routes/jp/tarot";
import About_jp from "./routes/jp/about";
import Contact_jp from "./routes/jp/contact";
import Oeuvres_jp from "./routes/jp/oeuvres";

function App() {
    const location = useLocation();
    const {name} = useParams();

    const getClassNameForRoute = () => {
        switch (location.pathname) {
            case '/':
                return 'top-page';
            case '/oeuvres' :
                return 'oeuvres-page';
            case '/tarot':
                return 'tarot-page';
            case '/biographie':
                return 'bio-page';
            case '/contact':
                return 'contact-page';
            case '/en':
                return 'top-page';
            case '/en/oeuvres' :
                return 'oeuvres-page';
            case '/en/tarot':
                return 'tarot-page';
            case '/en/biographie':
                return 'bio-page';
            case '/en/contact':
                return 'contact-page';
            case '/jp':
                return 'top-page';
            case '/jp/oeuvres' :
                return 'oeuvres-page';
            case '/jp/tarot':
                return 'tarot-page';
            case '/jp/biographie':
                return 'bio-page';
            case '/jp/contact':
                return 'contact-page';
            default:
                return '';
        }
    };

    const className = getClassNameForRoute();

    let [datas, setDatas] = useState([{}]);

    const makelist = (array) => {
        const [header, ...rows] = [...array];
        return rows.map((row) =>
            row.reduce((prev, curr, i) => ({...prev, [header[i]]: curr}), {})
        ).sort((a, b) => b.id - a.id).filter((el) => el.url);
    }

    const getworksfromGss = () => {
        fetch("https://sheets.googleapis.com/v4/spreadsheets/19Fk8tzP3077W0bDqO0r-3DiJ582Glb5k7O2DDTJGqIc/values/sheetworks?key=AIzaSyBP-f7WYOvwVPJrnPfTD1haOUPVlgeAGYA")
            .then(res => res.json())
            .then(datas => {
                const list = makelist(datas.values)
                setDatas(list)
            });
    }

    useEffect(() => {
        getworksfromGss()
    }, []);

    return (
        <div className={className + " App"}>
            <div id="top"></div>
            <Routes>
                <Route path="/" element={<Home works={datas}/>}/>
                <Route path="/oeuvres" element={<Oeuvres works={datas}/>}/>
                <Route path="/tarot" element={<Tarot/>}/>
                <Route path="/biographie" element={<About/>}/>
                <Route path="/contact" element={<Contact proptxt="Hello!"/>}/>
                <Route path="/master" element={<Master/>}/>
                <Route path="*" element={<NoMatch/>}/>
                <Route path="/en">
                    <Route index element={<Home_en works={datas}/>}/>
                    <Route path="oeuvres" element={<Oeuvres_en works={datas}/>}/>
                    <Route path="tarot" element={<Tarot_en/>}/>
                    <Route path="biographie" element={<About_en/>}/>
                    <Route path="contact" element={<Contact_en/>}/>
                </Route>
                <Route path="/jp">
                    <Route index element={<Home_jp works={datas}/>}/>
                    <Route path="oeuvres" element={<Oeuvres_jp works={datas}/>}/>
                    <Route path="tarot" element={<Tarot_jp/>}/>
                    <Route path="biographie" element={<About_jp/>}/>
                    <Route path="contact" element={<Contact_jp/>}/>
                </Route>
            </Routes>
        </div>)
        ;
}

export default App;
