import {NavLink, useNavigate} from "react-router-dom";
import '../../index.css';
import '../../style/about.css';
import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import tarots5 from "../../asset/tarot5.jpg";
import tarots7 from "../../asset/tarot7.jpg";
import tarots15 from "../../asset/tarot15.jpg";
import tarots16 from "../../asset/tarot16.jpg";
import tarots19 from "../../asset/tarot19.jpg";
import "../../style/tarot.css"

import "../../style/tarot.css"

function Tarot_jp() {
    const navigate = useNavigate();
    useEffect(() => {
        const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD');
        const PopUpElementb = document.querySelectorAll('.fade-inslow');

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }

        function showElementsb(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in_bg');
                }
            });
        }

        const options = {
            root: null, // 今回はビューポートをルート要素とする
            rootMargin: "0px 0px -10%", // ビューポートの中心を判定基準にする
            threshold: 0 // 閾値
        };
        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
        const observerb = new IntersectionObserver(showElementsb, options);
        PopUpElementb.forEach(item => {
            observerb.observe(item);
        });
    });


    function jumptop() {
        const element = document.getElementById("top");
        element.scrollIntoView();
    }

    return (
        <div className={"subpage tarot_page style_jp"}>
            <Helmet>
                <title>タロットリーディング | Hiroki KURODA Site Officiel </title>
            </Helmet>
            <h2 className="Corsiva fade-inD">マルセイユ<br/>タロットリーディング</h2>

            <p className="fade-in ">タロットリーディングとは、古代から受け継がれてきた神秘的なカード占いの一種です。</p>
            <p className="fade-in ">タロットカードは、豊かなシンボリズムと図像を持つカードセットであり、
                過去、現在、未来の出来事やエネルギーを読み解くために使用されます。</p>
            <p className="fade-in ">占い師は、カードの配置、カード同士の関連性、シンボルの意味などを組み合わせて、
                あなたの状況や質問に対する洞察をお答え、あなた自身の内面に気付きをもたらします。</p>
            <p className="fade-in ">私が自然やスピリチュアルと触れる中で手に入れた感覚で精霊のメッセージをカードで聞き、言葉にしてお伝えします。</p>
            <p className="fade-in ">この占術は科学的根拠に基づくものではなく、個人の直感や解釈に依存するため、さまざまな解釈が可能です。</p>
            <p className="fade-in ">タロットに質問することは、人生の方向性を考える手段としてもあなたを助けますが、深層心理の探求や創造的な問題解決にも役立つことでしょう。</p>
            <p className="fade-in ">日本語でのみ対応しています。 <br/> 40euros/ 1h</p>


            <img src={tarots5} alt="tarots" className="fade-inslow bgtarot01"/>
            <img src={tarots7} alt="tarots" className="fade-inslow bgtarot02"/>
            <img src={tarots15} alt="tarots" className="fade-inslow bgtarot03"/>
            <img src={tarots19} alt="tarots" className="fade-inslow bgtarot04"/>
            <img src={tarots16} alt="tarots" className="fade-inslow bgtarot05"/>
            <div className="btn_palette palettered fade-in" onClick={jumptop}>
                <NavLink to="jp/contact" className="">
                    お問い合わせへ
                </NavLink>
            </div>
        </div>);
}

export default Tarot_jp;


/*

<img src={tarots15} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots19} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots16} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots} alt="tarots" className="fade-inslow bgtarot"/>


 */