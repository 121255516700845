import {NavLink, useNavigate} from "react-router-dom";
import '../index.css';
import '../style/about.css';
import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import tarots from "../asset/tarotversonn.png";
import tarots5 from "../asset/tarot5.jpg";
import tarots7 from "../asset/tarot7.jpg";
import tarots13 from "../asset/tarot13.jpg";
import tarots15 from "../asset/tarot15.jpg";
import tarots16 from "../asset/tarot16.jpg";
import tarots17 from "../asset/tarot17.jpg";
import tarots19 from "../asset/tarot19.jpg";
import table from "../asset/table.webp";

import "../style/tarot.css"

function Tarot() {
    const navigate = useNavigate();
    useEffect(() => {
        const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD');
        const PopUpElementb = document.querySelectorAll('.fade-inslow');

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }

        function showElementsb(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in_bg');
                }
            });
        }

        const options = {
            root: null, // 今回はビューポートをルート要素とする
            rootMargin: "0px 0px -10%", // ビューポートの中心を判定基準にする
            threshold: 0 // 閾値
        };
        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
        const observerb = new IntersectionObserver(showElementsb, options);
        PopUpElementb.forEach(item => {
            observerb.observe(item);
        });
    });


    function jumptop() {
        const element = document.getElementById("top");
        element.scrollIntoView();
    }

    return (
        <div className={"subpage tarot_page"}>
            <Helmet>
                <title>Tirage de Tarot | Hiroki KURODA Site Officiel </title>
            </Helmet>
            <h2 className="Corsiva fade-inD">Tirage de Tarot de Marseille</h2>
            <p className="fade-in ">La lecture du <b>"Tarot"</b> est un type de divination mystique par les cartes qui a
                été transmis depuis les temps
                anciens.
                Les cartes de tarot sont un ensemble de cartes <b>au symbolisme et à l'iconographie riches</b>,
                utilisées pour déchiffrer les événements et les énergies passés, présents et futurs.
            </p>
            <p className="fade-in">
                La cartomancienne combine l'emplacement des cartes, leur relation les unes avec les autres et la
                signification des symboles,
                Elle vous aide à comprendre votre situation ou vos questions et vous amène à <b>une prise de conscience
                intérieure </b>de votre propre personne.
            </p>
            <p className="fade-in"> J'écoute <b>les messages des esprits</b> avec les cartes et je les mets en mots, en
                utilisant les sens que j'ai
                acquis au contact de <b>la nature et de la spiritualité.</b>
            </p>

            <p className="fade-in">
                Cette technique de divination n'est pas basée sur des preuves scientifiques, mais repose sur l'intuition
                et l'interprétation personnelles et est donc ouverte à différentes interprétations.
            </p>

            <p className="fade-in">
                Poser des questions au Tarot peut vous <b>aider à réfléchir à l'orientation de votre vie,</b> mais cela
                peut
                aussi être utile pour une exploration psychologique profonde et la résolution créative de problèmes.
            </p>


            <p className="tarot_info">Disponible en japonais uniquement.</p>
            <p className="tarot_info"> 40euros/ 1h</p>

            <img src={tarots5} alt="tarots" className="fade-inslow bgtarot01"/>
            <img src={tarots7} alt="tarots" className="fade-inslow bgtarot02"/>
            <img src={tarots15} alt="tarots" className="fade-inslow bgtarot03"/>
            <img src={tarots19} alt="tarots" className="fade-inslow bgtarot04"/>
            <img src={tarots16} alt="tarots" className="fade-inslow bgtarot05"/>
            <div className="btn_palette palettered fade-in" onClick={jumptop}>
                <NavLink to="/contact" className="">
                    Me Contacter
                </NavLink>
            </div>
        </div>);
}

export default Tarot;


/*

<img src={tarots15} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots19} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots16} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots} alt="tarots" className="fade-inslow bgtarot"/>


 */