import {NavLink, useLocation, useNavigate} from "react-router-dom";
import "../style/footer.css"
import React from "react";

function Footer() {
    const navigate = useNavigate();
    const location = useLocation();

    function totop() {
        const element = document.getElementById("top");
        element.scrollIntoView();
    }

    function footerByLangue() {
        const extractedChars = location.pathname.substring(1, 3);
        console.log(extractedChars);
        switch (extractedChars) {
            case 'en':
                return (
                    <ul>
                        <li><NavLink
                            style={({isActive}) => (isActive ? {color: "#1a1a1a"} : undefined)}
                            to="en/">
                            Home
                        </NavLink></li>

                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="en/oeuvres">
                            Art Gallery
                        </NavLink></li>
                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="en/tarot">
                            Reading the Tarot
                        </NavLink></li>

                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="en/biographie">
                            Biography
                        </NavLink></li>
                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="en/contact">
                            Contact
                        </NavLink></li>
                    </ul>
                );
            case 'jp':
                return (
                    <ul className="style_jp">
                        <li><NavLink
                            style={({isActive}) => (isActive ? {color: "#1a1a1a"} : undefined)}
                            to="jp/">
                            ホーム
                        </NavLink></li>

                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="jp/oeuvres">
                            アートギャラリー
                        </NavLink></li>
                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="jp/tarot">
                            タロットリーディング
                        </NavLink></li>

                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="jp/biographie">
                            プロフィール
                        </NavLink></li>
                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="jp/contact">
                            お問い合わせ
                        </NavLink></li>
                    </ul>
                );
            default:
                return (
                    <ul>
                        <li><NavLink
                            style={({isActive}) => (isActive ? {color: "#1a1a1a"} : undefined)}
                            to="/">
                            Accueil
                        </NavLink></li>

                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="/oeuvres">
                            Œuvres d'Art
                        </NavLink></li>
                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="/tarot">
                            Tirage de Tarot
                        </NavLink></li>

                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="/biographie">
                            Biographie
                        </NavLink></li>
                        <li><NavLink
                            className={({isActive}) => (isActive ? 'active' : undefined)}
                            to="/contact">
                            Contact
                        </NavLink></li>
                    </ul>
                );
        }
    }

    return (
        <div className="footer">
            <div className="footer_main" onClick={totop}>
                <div className="footer_logo Quicksand">
                    <p>Hiroki KURODA</p>
                </div>
                {footerByLangue()}
            </div>
            <div className="footer_bottom">
                <p>© 2023 · Hiroki KURODA · ALL RIGHTS RESERVED </p>
                <p> Crédit Site Web : TAKAHIRO YAMADA</p>
            </div>
        </div>);
}

export default Footer;