import {NavLink, useNavigate} from "react-router-dom";
import '../../index.css';
import '../../style/about.css';
import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import tarots5 from "../../asset/tarot5.jpg";
import tarots7 from "../../asset/tarot7.jpg";
import tarots15 from "../../asset/tarot15.jpg";
import tarots16 from "../../asset/tarot16.jpg";
import tarots19 from "../../asset/tarot19.jpg";
import "../../style/tarot.css"

function Tarot_en() {
    const navigate = useNavigate();
    useEffect(() => {
        const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD');
        const PopUpElementb = document.querySelectorAll('.fade-inslow');

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }

        function showElementsb(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in_bg');
                }
            });
        }

        const options = {
            root: null, // 今回はビューポートをルート要素とする
            rootMargin: "0px 0px -10%", // ビューポートの中心を判定基準にする
            threshold: 0 // 閾値
        };
        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
        const observerb = new IntersectionObserver(showElementsb, options);
        PopUpElementb.forEach(item => {
            observerb.observe(item);
        });
    });


    function jumptop() {
        const element = document.getElementById("top");
        element.scrollIntoView();
    }

    return (
        <div className={"subpage tarot_page"}>
            <Helmet>
                <title>Reading the Marseille Tarot | Hiroki KURODA Site Officiel </title>
            </Helmet>
            <h2 className="Corsiva fade-inD"> Reading the Marseille Tarot</h2>
            <p className="fade-in ">Tarot" reading is a type of mystical card divination that has been handed down since
                ancient times. Tarot cards are a set of cards with rich symbolism and iconography, used to decipher
                past, present and future events and energies.
            </p>
            <p className="fade-in ">The cartomancer combines the placement of the cards, their relationship to each
                other and the meaning of the symbols, helping you to understand your situation or questions and bringing
                you to an inner awareness of yourself.
            </p>
            <p className="fade-in "> I listen to the messages of the spirits with the cards and put them into words,
                using the senses I've acquired through contact with nature and spirituality.
            </p>
            <p className="fade-in ">This divination technique is not based on scientific evidence, but relies on
                personal intuition and interpretation, and is therefore open to different interpretations.
            </p>
            <p className="fade-in "> Asking Tarot questions can help you reflect on the direction of your life, but it
                can also be useful for deep psychological exploration and creative problem-solving.
            </p>
            <p className="fade-in ">Available in Japanese only. <br/>
                40euros/ 1h
            </p>
            <img src={tarots5} alt="tarots" className="fade-inslow bgtarot01"/>
            <img src={tarots7} alt="tarots" className="fade-inslow bgtarot02"/>
            <img src={tarots15} alt="tarots" className="fade-inslow bgtarot03"/>
            <img src={tarots19} alt="tarots" className="fade-inslow bgtarot04"/>
            <img src={tarots16} alt="tarots" className="fade-inslow bgtarot05"/>
            <div className="btn_palette palettered fade-in" onClick={jumptop}>
                <NavLink to="en/contact" className="">
                    Get in touch
                </NavLink>
            </div>
        </div>);
}

export default Tarot_en;


/*

<img src={tarots15} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots19} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots16} alt="tarots" className="fade-inslow bgtarot"/>
<img src={tarots} alt="tarots" className="fade-inslow bgtarot"/>


 */