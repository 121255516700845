import {Link, NavLink, Route, Routes, useNavigate} from "react-router-dom";
import '../../style/home.css';
import '../../index.css';
import React, {useEffect, useState} from "react";
import Contactform from "./contactform";
import {Helmet} from "react-helmet-async";
import tarots from "../../asset/tarotversonn.png";
import topimg from "../../asset/topbg1.jpg";


function Home_en(props) {

    const navigate = useNavigate();
    const [datas, setDatas] = useState([]);
    const [bgimg1, setBgimg1] = useState(topimg);
    let [numbg, setNumbg] = useState(0);
    let [numanime, setNumAnime] = useState(1);
    const bglist = [
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk004.png?alt=media&token=33b049ab-6321-448f-85c2-4ae7d2c0ea51",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk006.png?alt=media&token=78fd1842-0354-4196-8658-53cf0e94365e",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk021.png?alt=media&token=15785b69-546e-4bb5-98a6-4a3bc746a522",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk022.png?alt=media&token=36665caa-08c5-49fd-90f8-0472b4eb8eba",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk025.png?alt=media&token=ab1a538f-5cc8-42d7-8be8-d291e4e9da74",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk030.png?alt=media&token=b36714bf-3700-434e-8220-71d108705b64",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk029.png?alt=media&token=6ba507fe-9ac4-475d-ba81-b20203973827",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk010.png?alt=media&token=908e7ff2-6d6e-40ce-9824-e7520b4f7ae9",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk011.png?alt=media&token=fd64e367-ca6b-481e-b36b-cb9be622134e",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk012.png?alt=media&token=630cacdb-d0c7-4c2b-a81f-d1fbd51e2770",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk016.png?alt=media&token=a387da8b-979f-4390-8385-893cb611861f",
        "https://firebasestorage.googleapis.com/v0/b/kuroda-site.appspot.com/o/portfolios%2Fhk007.png?alt=media&token=79bb4606-5ff3-4ebe-b163-e0842269eaa2",
        topimg
    ]


    function interval() {
        setTimeout(() => {
                let index = numbg % bglist.length;
                setBgimg1(bglist[index])
                setNumbg(numbg + 1);
                setNumAnime(2 - (numbg % 2))
            },
            4000);
        return () => clearInterval(interval);
    }

    const PopUpElement = document.querySelectorAll('.fade-in,.fade-inR, .fade-inD');

    useEffect(() => {
        interval();
    }, [numbg])

    useEffect(() => {
        setDatas(props.works)
        const options = {
            root: null,
            rootMargin: "0px 0px -20%",
            threshold: 0
        };

        function showElements(array) {
            array.forEach(item => {
                if (item.isIntersecting) {
                    item.target.classList.add('isfade_in');
                }
            });
        }

        const observer = new IntersectionObserver(showElements, options);
        PopUpElement.forEach(item => {
            observer.observe(item);
        });
    })

    function jumptop() {
        const element = document.getElementById("top");
        element.scrollIntoView();
    }

    const is_titled = ((title) => {
        if (title) {
            return title
        } else {
            return "sans titre"
        }
    })

    const renderNouvaute = (() => {
        const result = [];
        const array = [...props.works]
        for (let i = 0; i < array.length; i++) {
            if (i < 4) {
                if (array[i].url && array[i].indisponible === 'TRUE') {
                    result.push(
                        <div key={i} className="nouveautes_slider_item fade-inR fade-in_delayed">
                            <div className="n_imgbox n_nonsidp"><img src={array[i].url} alt=""/></div>
                            <p className="n_item_title">{is_titled(array[i].name_en)}</p>
                            <p className="n_item_size">{array[i].tatecm}cm × {array[i].yokocm}cm</p>
                            <p className="n_item_year">{array[i].year}</p>
                            <p className="nonsales">Not available</p>
                        </div>)
                }
                if (array[i].url && array[i].indisponible === 'FALSE') {
                    if (array[i].vendu === 'TRUE') {
                        result.push(
                            <div key={i} className="nouveautes_slider_item fade-inR fade-in_delayed">
                                <div className="n_imgbox n_sold"><img src={array[i].url} alt=""/></div>
                                <p className="n_item_title">{is_titled(array[i].name_en)}</p>
                                <p className="n_item_size">{array[i].tatecm}cm × {array[i].yokocm}cm</p>
                                <p className="n_item_year">{array[i].year}</p>
                                <p className="undisp">Sold</p>
                            </div>)

                    } else {
                        result.push(
                            <div key={i} className="nouveautes_slider_item fade-inR fade-in_delayed">
                                <div className="n_imgbox"><img src={array[i].url} alt=""/></div>
                                <p className="n_item_title">{is_titled(array[i].name_en)}</p>
                                <p className="n_item_size">{array[i].tatecm}cm × {array[i].yokocm}cm</p>
                                <p className="n_item_year">{array[i].year}</p>
                                <p className="n_item_price"> {array[i].price} € </p>
                            </div>)
                    }
                }
            }
        }
        return <div className="nouveautes_slider " id="n_slider">
            {result}
            <div className="nouveautes_slider_item flex_centering" onClick={jumptop}>
                <NavLink className="n_item_btn" to="/en/oeuvres">
                    <b>See all
                        <br/>my workss</b>
                </NavLink>
            </div>
        </div>;
    })

    return (
        <div className="home">
            <Helmet>
                <title>Hiroki KURODA Site Officiel | Artist peintre / tarologue</title>
            </Helmet>

            <div className="header_container" id="header_container">
                <div className="bgimage" style={{
                    backgroundImage: "url(" + bgimg1 + ")",
                    animation: "fade-in-animation" + numanime + " 4s linear forwards",
                }}></div>
                <h1><span className="h1_s">painter / tarot reader</span><br/>
                    <span className="txt_white">Hiroki<br/> KURODA</span>
                </h1>
                <h2 className="txt_white">The spilitual art<br/>
                    that lights up your room.</h2>
                <button className="header_btn" onClick={jumptop}>
                    <NavLink to="/en/oeuvres">
                        Art Gallery >
                    </NavLink>
                </button>
            </div>

            <div className="page_content nouveautes fade-inR">
                <h2 className="Corsiva">New releases</h2>
                {renderNouvaute()}
            </div>

            <div className="page_content sec_concept ">
                <h2 className="fade-in">The spiritual art to protect yourself and enrich your spirit.</h2>
                <p className="fade-in">
                    The stars are reflected on the tranquil surface of the clear water, while the echoes of a waterfall
                    resonate through a flourishing forest.<br/><br/>
                    I provide you with a space where spiritual power resides and envelops you. My paintings are a fusion
                    of natural beauty and spiritual energy.
                </p>
                <div className="btn_palette fade-in btnbl">
                    <NavLink to="/en/oeuvres" className="" onClick={jumptop}>
                        Art Gallery
                    </NavLink>
                </div>
            </div>


            <div className="page_content sec_tarot">
                <h2>Reading <br/>the Marseille</h2>
                <p className="fade-in">
                    I also offer tarot sessions.<br/>
                    I can translate messages from nature and the spirits into words. <br/>
                    I use the Tarot de Marseille, which is the oldest and most closely related to numbers and symbols.
                </p>
                <img src={tarots} alt="tarots"/>
                <div className="btn_palette fade-in">
                    <NavLink to="/en/tarot" className="" onClick={jumptop}>
                        see more
                    </NavLink>
                </div>
            </div>

            <div className="page_content top_contact ">
                <h2>Contact</h2>
                <Contactform/>
            </div>

        </div>);
}


export default Home_en;

/*

  <h2>あなたを守るスピリチュアルアートで心を豊かに</h2>
            <p>明鏡止水の穏やかな水面に映る星々、繁茂した森の中に広がる滝の響き。</p>
            <p> スピリチュアルな力が宿り、あなたを包み込む空間をお届けします。私たちの絵画は、自然の美しさとスピリチュアルなエネルギーが融合した作品です。
            </p>
            <p> 守護する存在たちが、あなたを見守り、心を安らかに導いてくれます。その一瞬一瞬に込められたメッセージは、直感とインスピレーションを呼び覚まし、あなたの心に深い共鳴を生み出すでしょう。
            </p>
            <p> それぞれの絵画は、鮮やかな色彩と優れた技巧で描かれ、オーラを纏った特別な存在感を放っています。あなたの日常空間に飾れば、その力強いエネルギーが溢れ出し、心地よい癒しと喜びをもたらします。
            </p>
            <p> 自然とスピリチュアルの融合が生み出すこのアートは、あなたの内なる光を輝かせ、新たな旅へと誘います。魂を満たす守護の存在となり、どんな瞬間でもあなたのそばに寄り添い、あなたの人生にインスピレーションと幸福をもたらす――そんな特別なスピリチュアルアートの世界へ、いざ出発しましょう。
            </p>
 */

/*
* I also offer tarot reading sessions.
I can translate messages from nature and spirits into words for you.
I use the Marseilles Tarot, which is the oldest and most strongly linked to numbers.
*
*
* */
